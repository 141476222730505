import { nanoid } from '@reduxjs/toolkit';

import { browserStorageItem } from 'shared/constants/appConstants';
import { RoutePath } from 'shared/constants/routesConstants';
import { not } from 'shared/helpers/boolean';
import { AuthService } from 'shared/services/AuthService';
import history from 'shared/services/history';
import { clearUserSession, getSessionId } from 'shared/services/session';

type Method = 'get' | 'post' | 'put' | 'delete' | 'patch' | 'head';
type MethodUppercase = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';

interface RequestOptions<Result, Body> {
  createFullUrl?: boolean;
  data?: Body;
  headers?: Headers;
  isFormData?: boolean;
  method?: Method | MethodUppercase;
  path: URL | string;
  prefix?: string;
  query?: Record<string, unknown>;
  redirectToForbidden?: boolean;
  redirectToNotFound?: boolean;
  resolve?: (res: Response) => Promise<Result>;
  signal?: AbortSignal;
  allowNullValues?: boolean;
}

export async function request<Result = unknown, Body = any>(
  options: RequestOptions<Result, Body>
): Promise<Result> {
  const url = resolveUrl(options);

  if (typeof url !== 'string') {
    Object.entries(options.query ?? {}).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (item) {
            url.searchParams.append(key, String(item));
          }
        });
      } else if (
        not(
          (not(options.allowNullValues) && value === null) ||
            value === undefined
        )
      ) {
        url.searchParams.append(key, String(value));
      }
    });
  }

  const headers = options.headers || new Headers();

  headers.append('deviceid', getDeviceSessionID());

  const token = await AuthService.getToken();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  } else {
    const sessionId = getSessionId();
    if (sessionId) {
      headers.append('sessionid', sessionId);
    }
  }

  if (options.data && not(options.isFormData)) {
    headers.append('Content-Type', 'application/json');
  }

  const method = options.method.toUpperCase() || 'GET';

  const response = await fetch(url.toString(), {
    body: resolveBody(options),
    credentials: token ? 'include' : 'omit',
    headers: headers,
    method: method,
    signal: options.signal,
  });

  const status = response.status;

  if (status >= 300) {
    const error = await response.json();

    if (isSessionIdError(error)) {
      clearUserSession();
      return request(options);
    }

    const { redirectToForbidden = true, redirectToNotFound = true } = options;

    if (status === 403 && redirectToForbidden) {
      history.replace(RoutePath.FORBIDDEN);
    }

    if (status === 404 && redirectToNotFound) {
      history.replace(RoutePath.NOT_FOUND);
    }

    throw new APIRequestError({ error, method, status, url });
  }

  const resolve = options.resolve || ((res: Response) => res.json());

  return resolve(response);
}

export const ApiService = { request };

function resolveUrl(options: RequestOptions<unknown, unknown>): URL | string {
  const { createFullUrl = true } = options;
  if (createFullUrl) {
    const origin = window.location.origin;
    const prefix = '/api/' + (options.prefix ? `${options.prefix}/` : '');
    const path = trimForwardSlash(options.path);
    return new URL(origin + prefix + path);
  }
  return options.path;
}

function trimForwardSlash(path: URL | string): URL | string {
  if (typeof path === 'string' && path.startsWith('/')) {
    return path.slice(1);
  }
  return path;
}

function resolveBody(options: RequestOptions<unknown, unknown>) {
  if (options.data) {
    return options.isFormData
      ? (options.data as FormData)
      : JSON.stringify(options.data);
  }
  return undefined;
}

function isSessionIdError(error: Error & { status?: number }) {
  type ErrorPair = [number, string];
  const errors: ErrorPair[] = [
    [400, 'Session id is invalid'],
    [404, 'User by session id not found'],
  ];
  return errors.some(([status, message]) => {
    return error && error.status === status && error.message === message;
  });
}

export class APIRequestError<E = any> extends Error {
  data: E;
  method: string;
  status: number;
  url: URL | string;
  validationErrors?: Record<string, string | Record<string, string>>;

  constructor(options: {
    error: any;
    method: string;
    status: number;
    url: URL | string;
  }) {
    const name = 'API Request Error';
    const url = options.url.toString();

    const message = `${name}: ${options.method} ${url} - ${options.status}`;
    super(message);

    this.data = options.error;
    this.method = options.method;
    this.name = name;
    this.status = options.status;
    this.url = url;

    if (options.error.validationErrors) {
      this.validationErrors = options.error.validationErrors;
    }
  }
}

export function isAPIRequestError(error: unknown): error is APIRequestError {
  return error instanceof APIRequestError;
}

const idRegEx = /[a-zA-Z0-9-_]{21}/;
export function getDeviceSessionID() {
  let id = localStorage.getItem(browserStorageItem.deviceSessionUUID);
  if (not(id) || not(idRegEx.test(id))) {
    id = nanoid();
    localStorage.setItem(browserStorageItem.deviceSessionUUID, id);
  }
  return id;
}
